import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '@icons/wolfkit-light/trash-light.svg';
import InputComponent from '@shared/ui/inputs/Input';
import Icon from '@shared/ui/icons/Icon';
import { Body, BodySemiBold } from '@components/styled/Typography';
import IconButton from '@shared/ui/buttons/Button/IconButton';
import defineDisabledKeys from '../utils';
import SocialsSelect from './SocialsSelect';
import { ContainerColumn, ContainerRow } from '../../../styled';
const SocialsEditorContainer = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: 'auto',
}));
const SocialsSelectStyled = styled(SocialsSelect)(() => ({
    width: '100%',
    maxWidth: '156px',
    marginRight: 5,
}));
const Input = styled(InputComponent)(() => ({
    maxWidth: 239,
}));
const EmptySocialsSelect = styled(SocialsSelect)(props => ({
    '& .MuiInputBase-root': {
        maxWidth: 156,
    },
    '& .MuiOutlinedInput-input.MuiSelect-select': {
        padding: `${props.theme.spacing_sizes.xs * 1.25}px ${props.theme.spacing_sizes.xs * 4}px 
                    ${props.theme.spacing_sizes.xs * 1.25}px ${props.theme.spacing_sizes.xs * 1.5}px`,
    },
}));
const SocialFieldsRow = styled(ContainerRow)(props => ({
    width: '100%',
    height: 'auto',
    marginBottom: props.theme.spacing_sizes.m,
    gap: props.theme.spacing_sizes.xs,
}));
const DeleteIconSized = () => (_jsx(Icon, { size: 20, IconComponent: DeleteIcon }));
const EditorLabel = styled(BodySemiBold)(() => ({
    lineHeight: '20px',
}));
const EditorLabelContainer = styled(ContainerRow)((props) => ({
    display: 'flex',
    height: 'auto',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    paddingRight: props.theme.spacing_sizes.xs,
    marginBottom: props.theme.spacing_sizes.xs * 1.25,
}));
const SocialsCounter = styled(Body)(props => ({
    fontSize: 10,
    color: props.theme.palette.text.secondary,
}));
const LimitText = styled(Body)(props => ({
    fontSize: 14,
    color: props.theme.customColors.input.borderHover,
}));
const SocialsEditor = ({ label = undefined, onChange, initialValues, errors = undefined, limit = undefined, limitExceedText = undefined, onBlur = undefined, disabled = undefined, }) => {
    var _a, _b;
    const [links, setLinks] = useState(initialValues);
    const [disabledKeys, setDisabledKeys] = useState(() => defineDisabledKeys(initialValues));
    const [elementsCount, updateElementsCount] = useState(((_a = Object.keys(initialValues)) === null || _a === void 0 ? void 0 : _a.length) || 0);
    const { t } = useTranslation();
    const handleLinkValueChange = (value, key) => {
        setLinks(prev => {
            onChange(Object.assign(Object.assign({}, links), { [key]: Object.assign(Object.assign({}, links[key]), { value }) }));
            return Object.assign(Object.assign({}, prev), { [key]: Object.assign(Object.assign({}, links[key]), { value }) });
        });
    };
    const handleLinkTypeChange = (prevKey, prevType, newType) => {
        updateElementsCount((prev) => prev + 1);
        setLinks((prev) => {
            const updated = Object.assign(Object.assign({}, prev), { [`${newType}-${elementsCount + 1}`]: Object.assign(Object.assign({}, prev[prevKey]), { type: newType, key: `${newType}-${elementsCount + 1}` }) });
            delete updated[prevKey];
            onChange(updated);
            return updated;
        });
        setDisabledKeys((prevList) => {
            const result = [];
            prevList.forEach((element) => {
                if (element !== prevType) {
                    result.push(element);
                }
            });
            result.push(newType);
            return result;
        });
    };
    const handleLinkAdd = (key) => {
        updateElementsCount((prev) => prev + 1);
        setLinks((prev) => {
            onChange(Object.assign(Object.assign({}, prev), { [`${key}-${elementsCount + 1}`]: {
                    value: '',
                    type: key,
                    key: `${key}-${elementsCount + 1}`,
                } }));
            return Object.assign(Object.assign({}, prev), { [`${key}-${elementsCount + 1}`]: {
                    value: '',
                    type: key,
                    key: `${key}-${elementsCount + 1}`,
                } });
        });
        setDisabledKeys((prevList) => {
            prevList.push(key);
            return [...prevList];
        });
    };
    const handleLinkDelete = (key, type) => {
        setLinks((prev) => {
            const updatedValue = Object.assign({}, prev);
            delete updatedValue[key];
            onChange(Object.assign({}, updatedValue));
            return Object.assign({}, updatedValue);
        });
        setDisabledKeys((prevList) => prevList.filter((elem) => elem !== type));
    };
    const SelectLabel = useMemo(() => {
        var _a;
        if (!label) {
            return null;
        }
        const hasCounter = typeof limit === 'number';
        return (_jsxs(EditorLabelContainer, { children: [_jsx(EditorLabel, { children: label }), hasCounter && (_jsx(SocialsCounter, { children: `${(_a = Object.keys(links)) === null || _a === void 0 ? void 0 : _a.length} / ${limit}` }))] }));
    }, [links, limit, label]);
    const linkComponents = useMemo(() => {
        const linksArr = Object.keys(links);
        return (_jsxs(_Fragment, { children: [linksArr.map((key) => {
                    var _a, _b, _c, _d;
                    const socialsError = errors &&
                        ((_b = (_a = errors[key]) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.message);
                    return (_jsxs(SocialFieldsRow, { children: [_jsx(SocialsSelectStyled, { value: (_c = links[key]) === null || _c === void 0 ? void 0 : _c.type, onChange: (selected) => {
                                    handleLinkTypeChange(key, links[key].type, selected);
                                }, disabledKeys: disabledKeys, disabled: disabled }), _jsx(Input, { value: (_d = links[key]) === null || _d === void 0 ? void 0 : _d.value, onChange: (event) => handleLinkValueChange(event.target.value, key), onBlur: onBlur, size: 'small', placeholder: t('actions.enter_link', { ns: 'common' }), hiddenLabel: true, notification: socialsError || undefined, notificationStatus: socialsError ? 'error' : undefined, disabled: disabled }), _jsx(IconButton, { variant: 'tinted', color: 'primary-light', size: 'medium', IconComponent: DeleteIconSized, onClick: () => handleLinkDelete(key, links[key].type), disabled: disabled })] }, key));
                }), limit && (linksArr === null || linksArr === void 0 ? void 0 : linksArr.length) < limit ? (_jsx(SocialFieldsRow, { children: _jsx(EmptySocialsSelect, { onChange: handleLinkAdd, disabled: disabled, 
                        /*
                         if it would be undefined than
                         it would have unexpected selected icon
                        */
                        disabledKeys: disabledKeys, value: '' }) }, 'new')) : null] }));
    }, [links, elementsCount, disabled, disabledKeys, errors]);
    return (_jsxs(SocialsEditorContainer, { children: [SelectLabel, linkComponents, ((_b = Object.keys(links)) === null || _b === void 0 ? void 0 : _b.length) === limit && limitExceedText ?
                _jsx(LimitText, { children: limitExceedText }) :
                null] }));
};
export default SocialsEditor;
